import type { UnlikelyImage } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { serializeShopifyImages } from "~/components/ui/Image/_data/serializer"

export function getImagesByColor(selectedColor: Nullish<string>, shopifyImages: UnlikelyImage[]) {
  const images = serializeShopifyImages(shopifyImages)

  const pngOrJpgOrWebpImages = images.filter(
    (item) => item.src.indexOf(".png") > -1 || item.src.indexOf(".jpg") > -1 || item.src.indexOf(".webp") > -1
  )

  const imagesByColor = pngOrJpgOrWebpImages.filter((image) =>
    selectedColor ? image?.alt?.split("/")?.includes(selectedColor) : null
  )

  return imagesByColor.length > 0 ? imagesByColor : pngOrJpgOrWebpImages
}
