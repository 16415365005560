"use client"

import { type ComponentProps } from "react"
import clsx from "clsx"

import useGetCart from "~/hooks/cart/useGetCart"
import { useMobileTransparentNavigation } from "~/hooks/useMobileTransparentNavigation"
import Icon from "~/components/abstracts/Icon"
import { Cart } from "~/components/globals/Cart"
import { useCartState } from "~/components/globals/Cart/useCartState"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

type CartCtaProps = ComponentProps<"button">

export function CartCta({ className, ...props }: CartCtaProps) {
  const { add } = usePanel()
  const t = useTranslate()
  const { isFilledCart, cartArticlesNumber } = useGetCart()
  const setCurrentState = useCartState()[1]
  const { isWhiteIcon } = useMobileTransparentNavigation()

  function cartClicked() {
    setCurrentState("cart")
    add(<Cart />)
  }

  return (
    <button onClick={cartClicked} aria-label={t("aria_cart_open")} className={clsx(css.CartCta, className)} {...props}>
      <Icon
        className={sprinkles({ transition: "color-0.4s-ease" })}
        height={18}
        name="Cart"
        theme={{ mobile: isWhiteIcon ? "white" : "blue-deep", tablet: "blue-deep" }}
      />
      {isFilledCart && cartArticlesNumber && cartArticlesNumber > 0 && (
        <span
          className={clsx(
            css.indicator,
            text({
              design: "riposte-13",
              weight: "light",
              color: "inherit",
            }),
            sprinkles({
              color: { mobile: isWhiteIcon ? "white" : "blue-deep", tablet: "blue-deep" },
              transition: "color-0.4s-ease",
            })
          )}
        >
          {cartArticlesNumber}
        </span>
      )}
    </button>
  )
}
