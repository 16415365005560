"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import InlineCta from "~/components/ui/InlineCta"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { DesktopTrigger } from "~/components/globals/Navigation/components/Desktop/ClientDesktopTrigger"

import { sprinkles } from "~/styles/sprinkles.css"

type NavigationLinksProps = {
  links: NonNullable<TNavigation>["links"]
} & ComponentProps<"div">

export function NavigationLinks({ className, links, ...props }: NavigationLinksProps) {
  return (
    <div className={clsx(className)} {...props}>
      {links.map(({ link, colorLink }, index) => (
        <DesktopTrigger
          links={links}
          index={index}
          key={index}
          className={sprinkles({
            pX: 20,
          })}
        >
          {(isActive) => (
            <InlineCta
              customLineActive={isActive}
              className={sprinkles({
                letterSpacing: 1,
              })}
              transform="uppercase"
              weight="medium"
              style={colorLink ? { color: colorLink } : {}}
              lineStyle={colorLink ? { backgroundColor: colorLink } : {}}
              {...link}
            >
              {link.children}
            </InlineCta>
          )}
        </DesktopTrigger>
      ))}
    </div>
  )
}
