"use client"

import { useRef, type ComponentProps, type CSSProperties, type RefObject } from "react"
import clsx from "clsx"

import { AccordionPanel } from "@unlikelystudio/react-abstract-components"

import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { fromBezierToArray } from "~/utils/from-bezier-to-array"

import { sprinkles } from "~/styles/sprinkles.css"
import { easings } from "~/styles/variables/easings"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

const TRANSITION_DURATION = 0.5

export type PanelLinksWithTitleProps = {
  title: string
  titleStyle?: CSSProperties
} & ComponentProps<"div"> &
  (
    | { isMobile: true; index: number; rootRef?: RefObject<HTMLDivElement | null>; marginTop?: number }
    | { isMobile?: false; index?: never; rootRef?: never; marginTop?: never }
  )

export function PanelLinksWithTitle({
  title,
  isMobile,
  index,
  className,
  rootRef,
  children,
  marginTop,
  titleStyle,
  ...props
}: PanelLinksWithTitleProps) {
  const t = useTranslate()
  const ref = useRef<HTMLDivElement | null>(null)

  return isMobile ? (
    <AccordionPanel
      ref={ref}
      index={index}
      transition={{ duration: TRANSITION_DURATION, ease: fromBezierToArray(easings["easeInOutAccordion"]) }}
      className={clsx(css.MobilePanel, className)}
      headClassName={clsx(
        sprinkles({
          p: 20,
          width: "100%",
        })
      )}
      bodyClassName={sprinkles({ pX: 20 })}
      onOpen={() => {
        setTimeout(() => {
          const panelTop = ref.current?.getBoundingClientRect().y ?? 0

          rootRef?.current?.scrollTo({
            behavior: "smooth",
            top: rootRef?.current?.scrollTop + (panelTop - (marginTop ?? 0)),
          })
        }, TRANSITION_DURATION * 1000)
      }}
      ariaLabels={{
        open: t("aria_nav_mobile_accordion_open", { title }),
        close: t("aria_nav_mobile_accordion_close", { title }),
      }}
      head={(isActive) => (
        <div
          className={clsx(
            text({ transform: "uppercase", weight: "medium", letterSpacing: 0.6, design: "riposte-14" }),
            sprinkles({
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            })
          )}
          style={titleStyle}
        >
          {title} <Icon name={isActive ? "ChevronUp" : "ChevronDown"} width={14} />
        </div>
      )}
      body={
        <div className={clsx(sprinkles({ pB: 25, pT: 5, display: "flex", flexDirection: "column", gap: 25 }))}>
          {children}
        </div>
      }
    />
  ) : (
    <div
      className={clsx(
        className,
        sprinkles({ display: "flex", flexDirection: "column", gap: 20, alignItems: "flex-start" })
      )}
      {...props}
    >
      <p className={text({ transform: "uppercase", weight: "medium", letterSpacing: 1 })} style={titleStyle}>
        {title}
      </p>
      {children}
    </div>
  )
}
